dmx.Actions({

  'toast.setup' (options) {
    dmx.bs5toast.setup(this.parse(options));
  },

  'toast.show' (options) {
    dmx.bs5toast.show(this.parse(options));
  },

  'toast.showSimple' (options) {
    dmx.bs5toast.showSimple(this.parse(options));
  },

  'toast.clear' () {
    dmx.bs5toast.clear();
  },

});