dmx.bs5toast = {
  defaults: {
    iconType: 'image',
    position: 'top',
    align: 'right',
    offsetX: 15,
    offsetY: 15,
    //zIndex: 10000,
    minWidth: 240,
    maxWidth: 350,
    animation: true,
    autohide: true,
    closable: true,
    delay: 5000,
    headerClass: '',
    bodyClass: '',
    color: '',
  },

  container: null,

  setup (options) {
    options = Object.assign(dmx.bs5toast.defaults, options);

    if (!dmx.bs5toast.container) {
      dmx.bs5toast.container = document.createElement('div');
      document.body.appendChild(dmx.bs5toast.container);
    }

    const container = dmx.bs5toast.container;
    container.className = 'toast-container';
    container.removeAttribute('style');

    container.style.setProperty('position', 'fixed');
    //container.style.setProperty('z-index', options.zIndex);
    container.style.setProperty(options.align, options.offsetX + 'px');
    container.style.setProperty(options.position, options.offsetY + 'px');
    if (options.maxWidth) {
      container.style.setProperty('max-width', options.maxWidth + 'px');
    }
  },

  clear () {
    if (dmx.bs5toast.container) {
      dmx.bs5toast.container.textContent = '';
    }
  },

  show (options) {
    options = Object.assign({}, dmx.bs5toast.defaults, options);

    if (!dmx.bs5toast.container) {
      dmx.bs5toast.setup();
    }

    const node = dmx.bs5toast.create(options);
    dmx.bs5toast.container.appendChild(node);

    const toast = new bootstrap.Toast(node, options);
    node.addEventListener('hidden.bs.toast', () => dmx.bs5toast.container.removeChild(node), { once: true });
    toast.show();
  },

  showSimple (options) {
    options = Object.assign({}, dmx.bs5toast.defaults, options);

    if (!dmx.bs5toast.container) {
      dmx.bs5toast.setup();
    }

    const node = dmx.bs5toast.createSimple(options);
    dmx.bs5toast.container.appendChild(node);

    const toast = new bootstrap.Toast(node, options);
    node.addEventListener('hidden.bs.toast', () => dmx.bs5toast.container.removeChild(node), { once: true });
    toast.show();
  },

  create (options) {
    const node = document.createElement('div');
    node.className = 'toast';
    node.setAttribute('role', 'alert');
    node.setAttribute('aria-live', 'assertive');
    node.setAttribute('aria-atomic', 'true');
    node.setAttribute('data-delay', options.delay);

    if (options.minWidth) {
      node.style.setProperty('min-width', options.minWidth + 'px');
    }

    if (options.icon || options.title || options.subtitle || options.closable) {
      const header = document.createElement('div');
      header.className = 'toast-header';
      if (options.headerClass) header.className += ' ' + options.headerClass;

      if (options.icon) {
        const isImageFile = (icon) => /\.(gif|jpe?g|tiff?|png|webp|bmp)$/i.test(icon);

        if (isImageFile(options.icon)) {
          header.innerHTML = `<img src="${options.icon}" height="20" class="rounded me-2" alt="${options.alt || ''}">`;
        } else {
          header.innerHTML = `<i class="${options.icon} me-2"></i>`;
        }
      }

      if (options.title || options.subtitle || options.closable) {
        const strong = document.createElement('strong');
        strong.className = 'me-auto';
        strong.textContent = options.title || '';
        
        header.appendChild(strong);
      }

      if (options.subtitle) {
        const small = document.createElement('small');
        small.textContent = options.subtitle;

        header.appendChild(small);
      }

      if (options.closable) {
        const button = document.createElement('button');
        button.type = 'button';
        button.className = 'btn-close';
        button.setAttribute('data-bs-dismiss', 'toast');
        button.setAttribute('aria-label', 'Close');

        header.appendChild(button);
      }

      node.appendChild(header);
    }

    if (options.message) {
      const body = document.createElement('div');
      body.className = 'toast-body';
      if (options.bodyClass) body.className += ' ' + options.bodyClass;
      if (options.useHTML) {
        body.innerHTML = options.message;
      } else {
        body.textContent = options.message;
      }

      node.appendChild(body);
    }
    
    return node;
  },

  createSimple (options) {
    const node = document.createElement('div');
    node.className = 'toast align-items-center';
    node.setAttribute('role', 'alert');
    node.setAttribute('aria-live', 'assertive');
    node.setAttribute('aria-atomic', 'true');
    node.setAttribute('data-delay', options.delay);

    if (options.minWidth) {
      node.style.setProperty('min-width', options.minWidth + 'px');
    }

    if (options.type) {
      node.classList.add('border-0', `bg-${options.type}-subtle`, `text-${options.type}-emphasis`);
    }
    
    const container = document.createElement('div');
    container.className = 'd-flex';

    const body = document.createElement('div');
    body.className = 'toast-body ' + options.bodyClass;
    if (options.useHTML) {
      body.innerHTML = options.message;
    } else {
      body.textContent = options.message;
    }
    container.appendChild(body);

    if (options.closable) {
      const button = document.createElement('button');
      button.type = 'button';
      button.className = 'btn-close me-2 m-auto';
      button.setAttribute('data-bs-dismiss', 'toast');
      button.setAttribute('aria-label', 'Close');

      container.appendChild(button);
    }

    node.appendChild(container);

    return node;
  },
  
};